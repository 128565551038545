import Cookies from "js-cookie";
import logger from '@/shared/api/logger';
import stringToBase64 from "./stringToBase64";

export default (borrower?: { phone: string }) => {
    let vetrinaUrl = `https://hotzaem.ru?uid=${Cookies.get('borrowerUuid')}`

    const partner = Cookies.get('partner');
    const wmid = Cookies.get('wmid');

    if (partner) {
        vetrinaUrl+=`&source=${partner}`;
    }

    if (wmid) {
        vetrinaUrl+=`&wmid=${wmid}`;
    }
    
    if (borrower != null && borrower?.phone?.length > 0) {
        vetrinaUrl+=`&pe=${stringToBase64(borrower.phone)}`;
    }

    window.open(vetrinaUrl, '_self');

    logger('info', 'Переход на отказную витрину', { uuid: Cookies.get('borrowerUuid') });
}

